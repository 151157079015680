// stylelint-disable selector-max-compound-selectors, max-nesting-depth, selector-max-specificity

.card {
  // Spacing between vertically stacked cards
  // NOTE: This bottom margin should ALWAYS be set, even for the last child; because we list
  // cards out in complex layouts where they are embedded in the grid system, detecting last child
  // can become a false positive and lead to unintended compacted layouts
  margin-bottom: $card-margin-bottom;
  box-shadow: rgba(0, 0, 0, .1) 0 4px 12px;

  // Card title and optional title, when displayed in the header
  // NOTE: Bootstrap defines a .card-title for the body, so we must explicitly override
  // for the header
  .card-header {
    padding: 1rem 1.25rem;

    // Collapsed card, when only the header is visible
    &:last-child {
      border-bottom: 0;
      border-radius: $card-border-radius;
    }

    .card-title {
      display: inline-block;
      margin-bottom: 0;
    }

    .card-subtitle {
      display: inline-block;
      font-style: italic;
      opacity: 0.75;
      font-weight: $font-weight-regular;
      margin-left: $card-subtitle-spacing;
      padding-left: $card-subtitle-spacing;
      border-left: 1px solid $card-subtitle-border-color;
      margin-bottom: 0;
    }

    .card-state-toggle {
      font-size: larger;
      margin-right: 1rem;
    }
  }

  .card-body {

    // Spacing and alignment for embedded list views
    // NOTE: Bootstrap prescribes the list view as "flush" with the left/right of the
    // card by default, so we must adjust text alignment and borders to account for the custom
    // body padding (set in the _variables.scss)
    .list-group {
      border: 0;

      .list-group-item {
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
        border-right: 0;

        &:first-child {
          padding-top: 0;
          border-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }

        // Keep horizontal labels aligned with the text to the right
        label {
          &.col {
            margin-bottom: 0;
          }
        }
      }
    }

    // Intended for hosting PDFs or other browser-displayable docs
    .card-report {
      display: block;
      width: 100%;
      min-height: 50vh;
      border: 1px solid $card-border-color;
    }
  }

  // Card table views: .card .card-table
  &.card-table {
    .card-body {
      padding: 0;

      @include connected-table-variant();
    }
  }

  .card-footer {
    padding: 1rem 1.25rem;

    // NOTE: This is added specifically to support pagination design in the footer at small resolutions;
    // without this override, Bootstrap defaults inline forms to a vertical flow at small resolutions
    .form-inline {
      .form-control {
        @include media-breakpoint-down(sm) {
          display: inline-block;
          width: auto;
        }
      }
    }
  }

  // See _marquees.scss for all marquee styling
  .marquee {
    margin: 0.875rem 0;
  }
}

.card-deck {
  .card {
    margin-bottom: $card-margin-bottom;
  }
}

// Spacing and alignment for cards with multiple sections of readonly forms/text
// NOTE: The .card-section has been moved outside .card-body to allow for its use within modals (.modal-body),
// which are specialized cards
.card-section {
  margin-bottom: 2rem;

  // NOTE: Do NOT cancel out the bottom margin for the last card; for responsive layouts that stack at small
  // resolutions, this will disrupt the layout; instead, the design should make use of the mb-[resolution]-0
  // utility classes for controlling bottom margin
  //&:last-child {
  //  margin-bottom: 0;
  //}

  .card-section-header {
    .card-section-title {
      font-size: 1rem;
      margin-bottom: 1rem;
      display: inline-block;
    }

    .card-section-subtitle {
      font-size: $font-size-base;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 0;
      display: inline-block;
      margin-left: $card-section-subtitle-spacing;
      padding-left: $card-section-subtitle-spacing;
      border-left: 1px solid $card-subtitle-border-color;

      & h4 {
        // font-style: italic;
        font-weight: $font-weight-normal;
        font-size: $font-size-semi-small;
      }
    }
  }

  .table {
    // Add the bottom border (generally not visible) to tables within a card section
    // to provide visual separation from the next section or control and to avoid text clumping
    // (e.g., tables embedded in a searchable modal used to display paged results need separation
    // from the subsequent page navigation buttons)
    border-bottom: 1px solid $table-border-color;
  }
}
