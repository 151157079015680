.chip-tag {
  display: inline-block;
  border: 1px solid $ods-charcoal-light;
  border-radius: $border-radius;
  background-color: $ods-charcoal-ultra-light;
  font-size: 0.75rem;
  padding: $chip-tag-spacer-y $chip-tag-spacer-x;
  margin-right: $chip-tag-spacer-x;
  margin-bottom: $chip-tag-spacer-y;
  white-space: nowrap;

  .chip-tag-text {
    display: inline-block;
    vertical-align: middle;
  }

  .chip-tag-close {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    color: $ods-charcoal-light;
    margin-left: $chip-tag-spacer-x;

    &:hover {
      color: $ods-charcoal-core;
    }
  }

  // NOTE: It is important to use last-of-type instead of last-child; last-child means the last of the
  // anything appearing in a parent container, but since the last thing in the parent container in the clear link,
  // we want to specify the last of the .chip-tag in the parent container
  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    border-color: $ods-charcoal-medium;
    background-color: $ods-charcoal-lightest;
    cursor: pointer;
  }
}
