.text-caption {
  font-size: $small-font-size;
  color: $text-caption-color;
  display: block;
}

.text-placeholder {
  color: $text-muted;
  font-style: italic;
}

.text-magnified {
  font-size: $font-size-magnified;
  font-weight: bolder;
}

.text-icon-prefix {
  margin-right: 0.5rem;

  // Simulates .fa-fw with custom sizing
  text-align: center;
  width: 1.25rem;
}

.text-warning {
  // Set the warning text to a darker color so it passes accessibility testing
  // stylelint-disable declaration-no-important
  color: darken($ods-yellow-core, 25%) !important;
  // stylelint-enable declaration-no-important
}

.link-caption {
  font-size: $small-font-size;
}

.link-decorative {
  @include link-decorative-variant();
}

.link-download {
  @include link-decorative-variant();
  @include icon-suffix-variant($fa-arrow-to-bottom);
}

.link-external {
  @include link-decorative-variant();
  @include icon-suffix-variant($fa-external-link);
}
