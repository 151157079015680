.alert {
  padding: $alert-padding-y $alert-padding-x;

  // Keep the icon and message text together on a single line
  white-space: nowrap;

  &.alert-banner {
    border-radius: 0;
    margin-bottom: 0;
    padding: $alert-padding-y $container-content-padding-x-xs;

    @include media-breakpoint-up(sm) {
      padding: $alert-padding-y $container-content-padding-x-sm;
    }

    @include media-breakpoint-up(lg) {
      padding: $alert-padding-y $container-content-padding-x-lg;
    }
  }
}

.alert-heading {
  font-weight: $font-weight-medium;
  color: $ods-charcoal-core;
}

.alert-message {
  display: inline-block;

  // Allow text within the message body to wrap, while maintaining it to the right of the (optional) icon
  white-space: normal;

  p {
    margin-bottom: $alert-paragraph-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.alert-icon {
  margin-right: 0.625rem;
  display: inline-block;
  vertical-align: top;
  line-height: $line-height-base;

  // Simulate .fa-fw
  text-align: center;
  width: 1.25rem;

  & ~ .alert-message {
    // If the message appears to the right of the alert icon,
    // provide some additional offset to the right of the text so it looks
    // more centered
    // NOTE: Be VERY cautious of using sibling selectors like ~ and + in the CSS;
    // these break easily in Angular applications due to the additional HTML elements
    // introduced for components
    padding-right: 3rem;
  }
}

.alert-dismissible {
  .close {
    padding-left: 2.5rem;
    text-shadow: none;
  }

  .alert-message {
    // Keep the right margin clear so the close button stays visible for long text
    padding-right: 3rem;
  }
}

.alert-success,
.alert-primary {
  @include alert-inline-variant(
    rgba($ods-green-core, 0.3),
    rgba($ods-green-dark, 0.5),
    $ods-green-dark,
    $ods-charcoal-core
  );
}

.alert-info {
  @include alert-inline-variant(
    rgba($ods-blue-core, 0.3),
    rgba($ods-blue-dark, 0.5),
    $ods-blue-dark);
}

.alert-warning {
  @include alert-inline-variant(
    rgba($ods-yellow-core, 0.3),
    rgba($ods-yellow-dark, 0.7),
    $ods-yellow-dark);
}

.alert-danger {
  @include alert-inline-variant(
    rgba($ods-red-core, 0.2),
    rgba($ods-red-dark, 0.5),
    $ods-red-dark);
}
