// stylelint-disable selector-max-compound-selectors, selector-max-specificity, max-nesting-depth
// stylelint-disable no-descending-specificity

$container-navbar-left-width-collapsed: 77px;
$container-navbar-left-width-expanded: 300px;
$container-navbar-left-backdrop-bg: $modal-backdrop-bg;
$container-navbar-left-backdrop-opacity: $modal-backdrop-opacity;
$container-navbar-left-font-size: 1rem;
$container-navbar-left-font-color: $ods-white;
$container-navbar-left-item-indicator-expanded-color: rgba($ods-white, .75);

$container-navbar-left-horizontal-padding: 1.875rem;
$container-navbar-left-vertical-padding: 1.25rem;
$container-navbar-left-indicator-width: 1.25rem;

// NOTE: Overflow calculations are added for additional space needed for text indicators
$container-navbar-left-indicator-width-overflow: 1rem;

$container-content-padding-x-xs: 0.65rem;
$container-content-padding-x-sm: $container-content-padding-x-xs * 2;
$container-content-padding-x-lg: $container-content-padding-x-sm * 2;
$container-content-padding-y: 2.188rem;

// NOTE: When the navbar is collapsed, it should appear below the modal, even at the smallest resolution; only
// when the navbar is expanded should it cover any displayed modals
$zindex-container-navbar-left: $zindex-modal - 1;

$zindex-container-navbar-left-backdrop: $zindex-modal + 1;
$zindex-container-navbar-left-expanded: $zindex-container-navbar-left-backdrop + 1;

.container-layout {
  position: relative;
  min-height: 100vh;
  background-color: $container-background-color;

  .container-layout-right {
    margin-left: $container-navbar-left-width-collapsed;

    // Support for the non-modal left navbar
    &.offset-expanded-left {
      margin-left: $container-navbar-left-width-expanded;
    }
  }

  .container-navbar-left {
    position: absolute;
    height: 100%;
    overflow-x: hidden;
    z-index: $zindex-container-navbar-left;
    width: $container-navbar-left-width-collapsed;
    font-size: $container-navbar-left-font-size;
    background-image: linear-gradient(to right, $ods-blue-core, $ods-teal-core);
    color: $container-navbar-left-font-color;

    a {
      color: $container-navbar-left-font-color;
    }

    .nav-header {
      padding: $container-navbar-left-vertical-padding 0;

      .nav-title {
        // The nav title is hidden by default (when collapsed)
        display: none;
        text-transform: uppercase;
        color: $ods-blue-lighter;
      }

      .nav-close {
        // The close/collapse button is hidden by default (when collapsed)
        display: none;

        i {
          width: $container-navbar-left-indicator-width;
          text-align: right;
          font-size: 1.25rem;
        }

        &:hover {
          color: $ods-blue-lighter;
        }
      }

      .nav-expand {
        display: block;
        text-align: center;

        &:hover {
          color: $ods-blue-lighter;
        }
      }
    }

    .nav-footer {
      border-top: 1px solid rgba($ods-white, 0.2);
    }

    .nav-item-list {
      @include list-unstyled;

      margin-bottom: $container-navbar-left-vertical-padding * 3;

      .nav-item {
        // Keep the indicator and label together on a single line
        white-space: nowrap;

        a {
          display: block;

          // NOTE: Add padding to the link instead of the parent .nav-item so that the
          // entire area is clickable for navigation
          padding: $container-navbar-left-vertical-padding 0;
          text-align: center;
        }

        .nav-item-indicator {
          display: inline-block;
          position: relative;
          vertical-align: top;
          text-align: center;
          margin-right: 0;
        }

        .nav-badge-indicator {
          position: absolute;
          text-align: center;
          display: inline-block;
          border-radius: 50%;
          transition: transform .2s ease-in-out;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          pointer-events: none;
          width: 12px;
          height: 12px;
          //  Adjust position based on the size of the icon
          top: -6px;
          right: -6px;
        }

        & .warning {
          background-color: $ods-yellow-core;
        }

        .nav-item-label {
          // The label is hidden by default (when collapsed)
          display: none;
          font-weight: $font-weight-bold;

          // Allow text within the label to wrap, while maintaining it to the right of the indicator
          white-space: normal;

          // Adjust for the loss of padding caused by the indicator
          padding-right: $container-navbar-left-horizontal-padding;
        }

        .nav-item-caption {
          // The caption is hidden by default (when collapsed)
          display: none;
          font-weight: normal;

          // Allow text within the caption to wrap, while maintaining it to the right of the indicator
          white-space: normal;

          // Adjust for the loss of padding caused by the indicator
          padding-right: $container-navbar-left-horizontal-padding;
        }

        &.active {
          background-color: rgba($ods-blue-dark, 0.4);

          &:hover {
            background-color: rgba($ods-blue-dark, 0.6);
          }
        }

        &:hover {
          background-color: rgba($ods-blue-dark, 0.2);
        }
      }
    }

    &.expanded {
      z-index: $zindex-container-navbar-left-expanded;
      width: $container-navbar-left-width-expanded;

      .nav-header {
        padding: $container-navbar-left-vertical-padding $container-navbar-left-horizontal-padding;

        .nav-title {
          display: inline-block;
        }

        .nav-close {
          display: inline-block;
          float: right;
        }

        .nav-expand {
          display: none;
        }
      }

      .nav-item-list {
        .nav-item {
          a {
            padding: $container-navbar-left-vertical-padding $container-navbar-left-horizontal-padding;
            text-align: left;
          }

          // NOTE: For the menu item indicators in the main list (only), we extend the size beyond the desired
          // bounds for icons to allow for alt text indicators that require more width; this is done with
          // consideration to maintaining padding/margin ratios and minimizing impact to alignment; the additional
          // math is intended to make additional adjustments (e.g., allowing for more text width) easier in the future
          .nav-item-indicator {
            margin-left: calc($container-navbar-left-indicator-width-overflow / 2 * -1);
            margin-right: calc($container-navbar-left-vertical-padding - $container-navbar-left-indicator-width-overflow / 2);
            width: $container-navbar-left-indicator-width + $container-navbar-left-indicator-width-overflow;
            color: $container-navbar-left-item-indicator-expanded-color;
            display: inline-block;
            text-align: right;
          }

          .nav-item-label {
            display: inline-block;
          }

          .nav-item-caption {
            // The caption is hidden by default (when collapsed)
            display: block;
          }
        }
      }

      &.expanded-indicators-none {
        .nav-item-list {
          .nav-item {
            .nav-item-indicator {
              display: none;
            }
          }
        }
      }
    }
  }

  // NOTE: .container-navbar-top is not nested inside the .container-layout-right
  // because it can be used on layouts that have a left navbar (normal page mode)
  // as well as layouts that don't utilize it (error page mode)
  .container-navbar-top {
    background-color: $ods-charcoal-core;
    padding: 0 $container-content-padding-x-xs;

    .nav-title {
      font-size: 1.25rem;
      font-weight: normal;
      color: $ods-white;
      margin: 0;
    }

    .nav-logo {
      min-width: $logo-inline-width;
      max-width: $logo-inline-width;
    }

    .nav-multi-logo-large,
    .nav-logo-large {
      min-width: 10rem;
      max-width: 10rem;
    }

    .nav-dropdown {
      display: inline-block;

      .dropdown-toggle {
        @include button-variant(transparent, transparent, transparent, transparent, transparent, transparent);

        color: $ods-charcoal-lighter;
        padding: 0.25rem 0.5rem;

        // Support truncation of the user name for small resolutions
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(lg) {
          max-width: 175px;
        }

        @include media-breakpoint-down(md) {
          max-width: 100px;
        }

        &:focus,
        &.focus,
        &:active,
        &.active {
          box-shadow: none;
        }
      }
    }

    .nav-help {
      display: inline-block;
      margin-top: .25rem;
      color: $ods-charcoal-lighter;

      &:hover {
        color: $ods-white;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 0 $container-content-padding-x-sm;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 $container-content-padding-x-lg;
    }
  }

  // Utility for both the content and content-header sections; applied to the "Back" link above the title
  .content-close {
    display: inline-block;
    margin-bottom: 1.25rem;

    i {
      margin-right: 0.5rem;
    }
  }

  .container-content-header {
    background-color: $card-bg;
    border-bottom: 1px solid $card-border-color;
    padding: $container-content-padding-y $container-content-padding-x-xs 0 $container-content-padding-x-xs;

    @include media-breakpoint-up(sm) {
      padding: $container-content-padding-y $container-content-padding-x-sm 0 $container-content-padding-x-sm;
    }

    @include media-breakpoint-up(lg) {
      padding: $container-content-padding-y $container-content-padding-x-lg 0 $container-content-padding-x-lg;
    }

    .content-title {
      margin-bottom: 0;
      padding-bottom: $container-content-padding-y;
      display: inline-block;
    }

    .content-subtitle {
      display: inline-block;
      margin-left: $card-subtitle-spacing;
      padding-left: $card-subtitle-spacing;
      border-left: 1px solid $card-subtitle-border-color;
    }

    .content-title-supplemental {
      margin-bottom: 1rem;

      h2 {
        margin-bottom: 0;
      }
    }

    .content-divider {
      border-top-color: $card-border-color;
      margin: 0;
    }

    // Content sub-navigation tabs should closely match the styling for the
    // Filter Tabs, which were designed first
    .content-tabs {
      .nav-link {
        font-size: 1rem;
        padding: calc($filter-tab-spacer-x * (3 / 4)) $filter-tab-spacer-x;
        border-radius: 0;

        // Invisible borders are added for visual balance and alignment
        // with the active tab
        border-top: $filter-tab-active-border-width solid transparent;
        border-bottom: $filter-tab-active-border-width solid transparent;

        &:hover:not(.active) {
          border-bottom-color: $filter-tab-active-border-color;
        }

        &.active {
          background-color: transparent;
          color: $body-color;
          border-bottom-color: $filter-tab-active-highlight-border-color;
        }
      }
    }
  }

  // NOTE: .container-content is not nested inside the .container-layout-right
  // because it can be used on layouts that have a left navbar (normal page mode)
  // as well as layouts that don't utilize it (error page mode)
  .container-content {
    padding: $container-content-padding-y $container-content-padding-x-xs;

    @include media-breakpoint-up(sm) {
      padding: $container-content-padding-y $container-content-padding-x-sm;
    }

    @include media-breakpoint-up(lg) {
      padding: $container-content-padding-y $container-content-padding-x-lg;
    }

    &.container-content-sm {
      .content-close {
        margin-bottom: 0.25rem;
      }

      .content-title {
        font-size: 1.375rem;
        font-weight: $font-weight-bold;
        margin-bottom: 1rem;
      }
    }
  }
}

// This implementation is based on the .modal-backdrop in
// node_modules/bootstrap/scss/_modal.scss
.container-navbar-left-backdrop {
  // The left navbar backdrop is hidden by default and must be shown explicitly
  // when the navbar is in the "expanded" state
  @include backdrop-variant(
    $container-navbar-left-backdrop-bg,
    $container-navbar-left-backdrop-opacity,
    $zindex-container-navbar-left-backdrop
  );
}

// ng-select style
.ng-select.custom {
  .ng-select-container {
    background-color: $ods-charcoal-core;
    color: $ods-white;
    border: 10rem;
    padding-bottom: 0.4rem;
    max-width: 25rem;
    min-width: 25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    text-decoration: underline;
  }

  .ng-value-container {
    .ng-input > input:hover {
      cursor: pointer;
    }
  }

  .ng-dropdown-panel.ng-select-bottom {
    margin-top: 1px;
  }
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: $ods-white;
}
