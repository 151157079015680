
.modal-header {
  .close {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.modal-title {
  font-size: 1.25rem;
}

.modal-table {
  .modal-body {
    padding: 0;

    @include connected-table-variant();
  }
}

.modal-footer {
  padding: $modal-header-padding;

  // TODO: check if this still applies
  // Fix for Bootstrap 4.4.1: This version introduces additional margin for buttons
  // in the footer so wrapping at small resolutions looks better. This styling
  // disrupts the right alignment for larger resolutions, and because the design system
  // current targets larger resolutions, we adjust back to remove the margin and allow
  // for clean right-alignment
  @include media-breakpoint-up(sm) {
    .btn {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal-section-no-border {
  border: none;
}
