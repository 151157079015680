.event-table {
  th {
    position: relative;
    cursor: pointer;
  }

  h4 {
    font-size: 12px;
    font-weight: bold;
  }

  tr td {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .folder-icon {
    i {
      color: #9cafbe;
    }
  }

  .sub-row {
    background-color: rgba(238, 248, 255, 1);
  }

  .sub-row.first {
    box-shadow: inset 0 4px 10px #dfe7ee;
  }

  .hide {
    display: none;
  }

  a {
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
  }

  .lightColor {
    color: #62798a;
  }

  .icon {
    color: #62798a;
    width: 17px;
  }

  .expandedNameCol {
    display: grid;
    justify-content: start;
    grid-template-columns: fit-content(40px) fit-content(200px) 1fr;
    grid-gap: 20px;
  }

  .icons {
    position: absolute;
    top: 35%;
    right: 10px;
    display: inline-block;
  }

  .sortActivated {
    color: #000;
  }

  .export-icon {
    margin-bottom: 2px;
    margin-right: 4px;
  }

  .filterDashboard {
    background-color: #eeeff0;
    border-radius: 6px;
    border: 1px solid #d7dbe2;
  }
}
