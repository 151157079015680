// stylelint-disable selector-max-specificity, max-nesting-depth

.input-group {
  .input-group-text {
    background-color: $ods-white;
    color: $text-muted;
  }

  // Fix for Firefox, Edge, and IE11: The prepend/append controls don't pick up
  // the input height correctly, causing the input and button sections to look misaligned;
  // setting the height explicitly to the input height fixes the alignment
  .input-group-prepend,
  .input-group-append {
    height: $input-height;
  }
}

// Fix the height of the input group when the input is small
.input-group-sm {
  .input-group-prepend,
  .input-group-append {
    height: inherit;
  }
}

.input-img-btn-group {
  .input-group-append {
    .btn {
      border: $input-border-width solid $input-border-color;
      border-left: 0;
      color: $ods-charcoal-medium;
      background-color: $input-bg;

      &[disabled] {
        background-color: $input-disabled-bg;
        color: $form-control-disabled-color;
        opacity: 1;
      }
    }
  }

  .form-control {
    border-right: 0;

    &:focus + .input-group-append {
      .btn {
        border-color: $input-focus-border-color;
      }
    }
  }

  &.is-invalid {
    // stylelint-disable no-descending-specificity
    .input-group-append {
      .btn {
        border-color: $form-feedback-invalid-color;
      }
    }
    // stylelint-enable no-descending-specificity

    // Outline the composite elements in red when the date picker is invalid
    .form-control {
      border-color: $form-feedback-invalid-color;

      &:focus + .input-group-append {
        .btn {
          border-color: $form-feedback-invalid-color;
        }
      }
    }
  }
}
