$marquee-body-spacer-y: 1rem;
$marquee-image-spacer-y: 1.75rem;

.marquee {
  text-align: center;
  margin-top: 4rem;

  .marquee-icon {
    color: $ods-charcoal-lighter;
    font-size: 4.375rem;
    margin-bottom: $marquee-image-spacer-y;
  }

  .marquee-image {
    margin-bottom: $marquee-image-spacer-y;
  }

  .marquee-heading {
    margin-bottom: $marquee-body-spacer-y;
    font-weight: $font-weight-bold;
  }

  .marquee-message {
    margin-bottom: $marquee-body-spacer-y;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .marquee-primary-action,
  .marquee-secondary-action {
    // Because either buttons or hyperlinks can be used to display the primary and secondary actions,
    // we need a way to set these elements as block-level (force line breaks between them) without
    // causing the action to stretch the full width of the screen
    // https://css-tricks.com/injecting-line-break/
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  .marquee-primary-action {
    margin-bottom: $marquee-body-spacer-y;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.marquee-danger {
    .marquee-icon,
    .marquee-heading {
      color: $ods-red-core;
    }
  }
}
