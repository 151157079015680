.badge-success {
  @include blended-badge-variant(
    $badge-success-font-color,
    $badge-success-background-color,
    $badge-success-border-color
  );
}

.badge-danger {
  @include blended-badge-variant(
    $badge-danger-font-color,
    $badge-danger-background-color,
    $badge-danger-border-color
  );
}

.badge-warning {
  @include blended-badge-variant(
    $badge-warning-font-color,
    $badge-warning-background-color,
    $badge-warning-border-color
  );
}

.badge-info {
  @include blended-badge-variant(
    $ods-blue-dark,
    $ods-blue-lightest,
    $ods-blue-light
  );
}

.badge-secondary {
  @include blended-badge-variant(
    $ods-charcoal-dark,
    $ods-charcoal-ultra-light,
    $ods-charcoal-medium
  );
}

.badge {
  font-weight: $font-weight-medium;
  font-style: normal;
  border-radius: 2rem;
  padding: 0.3rem 0.75rem;
}

.inline-badge-img {
  max-height: 13px;
  margin-right: 0.325rem;
}

.inline-badge {
  display: inline;
}
