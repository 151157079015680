// stylelint-disable no-descending-specificity

// Overrides and supplementation to
// /node_modules/bootstrap/scss/_type.scss

// Primary font family
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Regular.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Regular.ttf") format("ttf");
}

// Italic
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Italic.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Italic.ttf") format("ttf");
}

// Medium
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Medium.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Medium.ttf") format("ttf");
}

// Medium Italic
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-MediumItalic.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-MediumItalic.ttf") format("ttf");
}

// Bold
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Bold.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-Bold.ttf") format("ttf");
}

// Italic bold
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-BoldItalic.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-BoldItalic.ttf") format("ttf");
}

// Semi-bold
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src:
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-SemiBold.woff2") format("woff2"),
    url("https://cdn.onyxcentersource.com/static/fonts/IBM-Plex-Sans/IBMPlexSans-SemiBold.ttf") format("ttf");
}

h1,
.h1 {
  @include font-size($h1-font-size);

  color: $h1-color;
  margin-bottom: $h1-margin-bottom;
}

h2,
.h2 {
  @include font-size($h2-font-size);

  font-weight: 500;
  color: $h2-color;
  margin-bottom: $h2-margin-bottom;
}

h3,
.h3 {
  @include font-size($h3-font-size);

  font-weight: $h3-font-weight;
  margin-bottom: $h3-margin-bottom;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @include font-size($hX-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

ul,
ol {
  &.list-unstyled {
    li {
      margin-bottom: 0.15rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.list-inline {
    &.list-inline-delimited {
      .list-inline-item {
        &:not(:last-child) {
          padding-right: $list-inline-padding;
          border-right: 1px solid $text-delimiter-color;
        }
      }
    }

    li {
      margin-bottom: 0;
    }
  }

  // Icon Lists
  &.fa-ul {
    &.fa-ul-align-left {
      // The margin is reduced instead of 0 because some
      // margin must remain for the icon bullet spacing
      margin-left: 1.5rem;
    }

    li {
      margin-bottom: 0.25rem;
    }
  }
}
