// stylelint-disable selector-max-specificity

.filter {
  margin-bottom: 2.5rem;
}

// Builds on top of the Bootstrap .nav .nav-tabs structure
// https://getbootstrap.com/docs/4.3/components/navs/#javascript-behavior
.filter-tabs {
  // Apply a border color that matches the active tab as well as the filter content area border
  border-bottom-color: $filter-tab-active-border-color;

  .nav-link {
    // NOTE: Borders are kept on and set to transparent (instead of a width of zero)
    // to avoid jumping effects when transitioning from inactive to active and/or hover states
    border-top: 1px solid $filter-tab-active-border-color;
    border-right-color: $filter-tab-inactive-border-color;
    border-left-color: $filter-tab-inactive-border-color;
    padding: $filter-tab-spacer-y 0;

    // Interior tabs are connected to each other via a solid unbroken line
    border-radius: 0;

    .nav-link-label {
      display: block;
      border-left: $filter-tab-inactive-border-width solid $ods-charcoal-lighter;
      border-right: $filter-tab-inactive-border-width solid transparent;

      // There is a design requirement to always reserve space for the second
      // caption line, even when it is not displayed; the implied design requirement
      // is that the tab never grows beyond the two lines of text (e.g., never word wraps)
      height: 2.5rem;
      white-space: nowrap;

      // This padding is paired with the padding of the parent .nav-link to achieve
      // the overall padding for each tab; we split these up to achieve the light
      // gray lines (that do not extend the full height of the tabs)
      // that separate inactive tabs
      padding: 0 $filter-tab-spacer-x;
    }

    &:first-child {
      // The left-most tab must have a rounded upper left corner and display the border
      // associated with the outer edge of the control
      border-left: $filter-tab-inactive-border-width solid $filter-tab-active-border-color;
      border-top-left-radius: $filter-tab-border-radius;

      .nav-link-label {
        border-left-color: transparent;
      }
    }

    &:last-child {
      // The right-most tab must have a rounded upper right corner and display the border
      // associated with the outer edge of the control
      border-right: 1px solid $filter-tab-active-border-color;
      border-top-right-radius: $filter-tab-border-radius;
    }

    h3 {
      // Keep the text vertically aligned, regardless of the width of the tab's top border
      margin-top: $filter-tab-active-border-width - $filter-tab-inactive-border-width;
      margin-bottom: 0;
    }

    .text-caption {
      font-weight: normal;
    }

    &.active {
      cursor: default;
      background-color: $filter-tab-active-background-color;
      border-top: $filter-tab-active-border-width solid $filter-tab-active-highlight-border-color;
      border-left: 1px solid $filter-tab-active-border-color;
      border-right: 1px solid $filter-tab-active-border-color;

      // Make the active tab appear connected to the filter content container below it
      border-bottom-color: $filter-tab-active-background-color;

      h3 {
        // The active tab shows a wider top border than an inactive tab, so to prevent
        // transition jumping (e.g., from inactive to active and/or hover) we
        // apply a top margin to inactive tabs to offset the border; this offset
        // can be cleared once the active state is reached
        margin-top: 0;
      }

      .nav-link-label {
        border-color: transparent;
      }
    }

    // NOTE: The active tab does not have a hover state because it can't be clicked again
    &:hover:not(.active) {
      border: 1px solid $filter-tab-active-border-color;
      border-top-width: $filter-tab-active-border-width;

      .nav-link-label {
        border-left-color: transparent;
      }

      h3 {
        margin-top: 0;
      }

      .text-caption {
        // Use the same darkening percentage that is applied to the button-variant hover
        color: darken($text-caption-color, 7.5%);
      }
    }
  }

  // Edge cases where we want to avoid a double border:
  // - a non-active tab to the right of an active tab does not need its shorter left border
  //   because of the solid border around the active tab
  // - a non-active tab to the right of a hovered non-active tab (for the same reasons as above)
  .nav-link.active + .nav-link:not(.active),
  .nav-link:hover:not(.active) + .nav-link:not(.active) {
    .nav-link-label {
      border-left-color: transparent;
    }
  }
}

.filter-content {
  // The filter content should appear connected to the active tab
  background-color: $filter-tab-active-background-color;
  padding: $filter-tab-spacer-x;
  border: 1px solid $filter-tab-active-border-color;
  border-top: 0;

  // Assume that the filter content will have tabs above it and a footer below it by default, so all
  // corners should be square in order to connect with the other parts of the layout
  border-radius: 0;

  &:last-child {
    // If the filter does not display a footer, round the bottom corners because they don't need
    // to be connected to a section below it
    border-radius: 0 0 $filter-tab-border-radius $filter-tab-border-radius;
  }
}

.filter-content-no-tab {
  // The filter content should appear connected to the active tab
  background-color: $filter-tab-active-background-color;
  padding: $filter-tab-spacer-x;
  border: 1px solid $filter-tab-active-border-color;

  // Assume that the filter content will have tabs above it and a footer below it by default, so all
  // corners should be square in order to connect with the other parts of the layout
  border-radius: 0;

  &:last-child {
    // If the filter does not display a footer, round the bottom corners because they don't need
    // to be connected to a section below it
    border-radius: 0 0 $filter-tab-border-radius $filter-tab-border-radius;
  }
}

.filter-tag {
  display: inline-block;
  border: 1px solid $ods-charcoal-light;
  border-radius: $border-radius;
  background-color: $ods-charcoal-ultra-light;
  font-size: 0.75rem;
  padding: $filter-tag-spacer-y $filter-tag-spacer-x;
  margin-right: $filter-tag-spacer-x;
  margin-bottom: $filter-tag-spacer-y;
  white-space: nowrap;

  .filter-tag-text {
    display: inline-block;
    vertical-align: middle;
  }

  .filter-tag-close {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    color: $ods-charcoal-light;
    margin-left: $filter-tag-spacer-x;

    &:hover {
      color: $ods-charcoal-core;
    }
  }

  // NOTE: It is important to use last-of-type instead of last-child; last-child means the last of the
  // anything appearing in a parent container, but since the last thing in the parent container in the clear link,
  // we want to specify the last of the .filter-tag in the parent container
  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    border-color: $ods-charcoal-medium;
    background-color: $ods-charcoal-lightest;
    cursor: pointer;
  }
}

.filter-tabs-footer {
  border: 1px solid $filter-tab-active-border-color;
  border-top-color: $filter-tab-active-background-color;
  background-color: $filter-tab-active-background-color;
  border-bottom-left-radius: $filter-tab-border-radius;
  border-bottom-right-radius: $filter-tab-border-radius;
  padding: $filter-tab-spacer-y $filter-tab-spacer-x;

  .filter-tag-group-label {
    margin-right: $filter-tab-spacer-x;
  }

  .filter-tag-group-clear {
    margin-left: $filter-tag-spacer-x;
  }
}
