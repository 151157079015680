// NOTE: The Angular component uses ngx-toastr to manage the alert toast elements, but the toastr-bs4-alert.scss
// should NOT be imported for use; we want the pattern library (CSS, assets) portion completely decoupled from
// the Angular portion so that non-Angular projects can still utilize the CSS
.alert-toast-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 2.5rem;
}

.alert-toast {
  @include border-radius($alert-border-radius);

  position: relative;
  margin: 0 auto .5rem auto;
  overflow: hidden;
  padding: $alert-padding-y $alert-padding-x;
  width: 600px;
  box-shadow: $floating-element-box-shadow;

  // Keep the icon and message text together on a single line
  white-space: nowrap;

  // Alerts should appear above all other elements, so we use the z-index of the topmost element in the system
  z-index: $zindex-tooltip + 1;

  .alert-toast-heading {
    font-weight: $font-weight-medium;
  }

  .alert-toast-message {
    display: inline-block;
    white-space: normal;

    // Keep the right margin clear so the close button stays visible for long text
    padding-right: 1.5rem;

    p {
      margin-bottom: $alert-paragraph-margin;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .alert-icon {
    & ~ .alert-toast-message {
      // If the message appears to the right of the alert icon,
      // provide some additional offset to the right of the text so it looks
      // more centered between the icon and close button
      // NOTE: Be VERY cautious of using sibling selectors like ~ and + in the CSS;
      // these break easily in Angular applications due to the additional HTML elements
      // introduced for components
      padding-right: 3rem;
    }
  }

  &.alert-toast-success {
    @include alert-toast-variant($ods-white, $ods-green-core, $ods-green-dark, rgba($ods-white, 0.8));
  }

  &.alert-toast-info {
    @include alert-toast-variant($ods-white, $ods-blue-core, $ods-blue-dark, rgba($ods-white, 0.8));
  }

  &.alert-toast-warning {
    @include alert-toast-variant($ods-charcoal-core, $ods-yellow-core, $ods-yellow-dark, rgba($ods-charcoal-core, 0.8));
  }

  // NOTE: The .alert-toast-error is added to accommodate the ngx-toastr package when using the AlertToastService
  // but should NOT be used for general structure or styling
  &.alert-toast-error,
  &.alert-toast-danger {
    @include alert-toast-variant($ods-white, $ods-red-core, $ods-red-dark, rgba($ods-white, 0.8));
  }
}

@include media-breakpoint-down(sm) {
  .alert-toast {
    width: 90%;
  }
}
