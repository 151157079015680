// stylelint-disable selector-max-compound-selectors, selector-max-specificity, max-nesting-depth
// stylelint-disable declaration-no-important

$datepicker-head-btn-active-background-color: $ods-blue-light;
$datepicker-body-disabled-font-color: $ods-charcoal-light;

@mixin datepicker-icon-btn-variant($icon, $icon-weight: $fa-fas-weight) {
  // NOTE: We cannot change the default previous/next buttons via configuration,
  // so we override the content with Font Awesome pseudo elements
  @include icon-prefix-variant($icon, $icon-weight);

  span {
    display: none;
  }

  &:hover {
    background-color: $datepicker-head-btn-active-background-color;
  }
}

// Theme for the datepicker popup
// Provides an Onyx-styled theme alternative to the default themes provided with ngx-bootstrap
// https://valor-software.com/ngx-bootstrap/#/datepicker#themes
.theme-ods {
  // Header/Navigation Bar
  .bs-datepicker-head {
    background-color: $ods-blue-core;

    .current {
      border: 1px solid $datepicker-head-btn-active-background-color;

      @include border-radius;

      &:hover {
        background-color: $datepicker-head-btn-active-background-color;
      }
    }

    .previous {
      @include datepicker-icon-btn-variant($fa-caret-left);

      &:disabled {
        // NOTE: The visibility is programmatically set on the style attribute, so we must use important
        // to override and hide the button
        visibility: hidden !important;
      }
    }

    .next {
      @include datepicker-icon-btn-variant($fa-caret-right);

      &:disabled {
        // NOTE: The visibility is programmatically set on the style attribute, so we must use important
        // to override and hide the button
        visibility: hidden !important;
      }
    }
  }

  // Calendar Selector
  .bs-datepicker-body {
    .weeks {
      // Weekday headings
      th {
        color: $ods-charcoal-medium;
      }
    }

    .days {
      span {
        color: $ods-charcoal-core;

        // NOTE: The .is-highlighted class is applied programmatically on hover
        &.is-highlighted {
          background-color: $ods-charcoal-ultra-light !important;
        }

        &.is-other-month {
          color: transparent;

          &.disabled {
            color: transparent;
          }
        }

        &.today {
          background-color: $ods-yellow-light;
        }

        &.selected {
          background-color: $ods-blue-core;
          color: $ods-white;

          &:hover {
            background-color: $ods-blue-core !important;
          }
        }

        &.disabled {
          color: $datepicker-body-disabled-font-color;
        }

        // Date Rangge Selection
        &.in-range {
          &:not(.select-end) {
            &::before {
              background-color: $ods-blue-lighter !important;

              // NOTE: Because of our adjustments to font size and line spacing, the
              // day selector cells do not touch like they do on the original ngx-bootstrap
              // control, so we must expand the edges so they touch and don't show gaps
              left: -8px;
              right: -8px;
            }
          }

          // The .in-range class is also applied to the .select-end last element, so we
          // must turn off the background range bar manually
          &.select-end,
          &.is-highlighted {
            &::before {
              background-color: transparent !important;
            }
          }
        }
      }
    }

    .months,
    .years {
      span {
        color: $ods-charcoal-core;
      }

      .disabled {
        span {
          color: $datepicker-body-disabled-font-color;
        }
      }
    }
  }
}

// Datepicker text input
// DEPRECATED: Use .input-img-btn-group defined in _input-groups.scss
.input-group-datepicker {
  @extend .input-img-btn-group;
}
