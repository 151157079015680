// Global variables and Bootstrap overrides
// NOTE: Bootstrap overrides must be declared BEFORE the Bootstrap defaults, per the theming documentation:
// https://getbootstrap.com/docs/4.3/getting-started/theming/#variable-defaults
@import "styles/variables";

// Custom Bootstrap 4.3 imports, patterned after node_modules/bootstrap/scss/bootstrap.scss;
// unused components have been removed to reduce the size of the build
// REQUIRED Bootstrap Imports
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// OPTIONAL Bootstrap Imports
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";

// Onyx Design System Styles
// NOTE: This import order follows the order specified in /node_modules/bootstrap/scss/bootstrap.scss
@import "styles/mixins";
@import "styles/reboot";
@import "styles/type";
@import "styles/utilities";
@import "styles/logos";
@import "styles/buttons";
@import "styles/dropdowns";
@import "styles/navbars";
@import "styles/forms";
@import "styles/complex-data-table";
@import "styles/datepickers";
@import "styles/button-groups";
@import "styles/input-groups";
@import "styles/tables";
@import "styles/cards";
@import "styles/badges";
@import "styles/filters";
@import "styles/marquees";
@import "styles/modals";
@import "styles/popovers";
@import "styles/chips";

// NOTE: Spinners must be declared after navbars because of a dependency for overlays
@import "styles/spinners";
@import "styles/custom-forms";
@import "styles/alerts";
@import "styles/toast-alerts";

// ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";
