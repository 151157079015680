// Reset //
strong {
  font-weight: $font-weight-semi-bold;
}

h2.marquee-message {
  font-weight: 400;
}

span.nav-item-label {
  font-weight: 400;
}

// Links
a {
  font-weight: $font-weight-medium;

  &.active,
  &:active {
    color: $ods-blue-core;
  }

  &.hover,
  &:hover {
    color: $ods-blue-dark;
  }
}

// Forms
label {
  font-weight: $font-weight-medium;
  font-size: $small-font-size;
  color: $ods-charcoal-dark;
}

// Tables
thead {
  th {
    font-weight: $font-weight-medium;
  }
}
