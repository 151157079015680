.logo {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;

  &.logo-inline-text,
  &.logo-inline-text-dark {
    background-image: url("/assets/logo-inline-text.svg");
    width: $logo-inline-width;
    height: $logo-inline-height;
  }

  &.logo-inline-text-light {
    background-image: url("/assets/logo-inline-text-light.svg");
    width: $logo-inline-width;
    height: $logo-inline-height;
  }

  &.logo-stacked-text,
  &.logo-stacked-text-dark {
    background-image: url("/assets/logo-stacked-text.svg");
    width: $logo-stacked-width;
    height: $logo-stacked-height;
  }

  &.logo-stacked-text-light {
    background-image: url("/assets/logo-stacked-text-light.svg");
    width: $logo-stacked-width;
    height: $logo-stacked-height;
  }

  &.logo-no-text {
    background-image: url("/assets/logo-no-text.svg");
    width: 10rem;
    height: 10rem;
  }
}
