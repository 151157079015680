.btn-primary {
  @include button-variant($ods-blue-core, $ods-blue-core, $ods-blue-dim, $ods-blue-dim);
}

.btn-regressive {
  @include button-variant($ods-charcoal-medium, $ods-charcoal-medium, $ods-charcoal-hub, $ods-charcoal-hub);
}

.btn-destructive {
  @include button-variant($ods-red-core, $ods-red-core, $ods-red-dim, $ods-red-dim);
}

.btn {
  font-weight: $font-weight-medium;
  line-height: $line-height-base;

  &.disabled,
  &:disabled {
    background-color: $ods-charcoal-ultra-light;
    color: $ods-charcoal-light;
    border-color: $ods-charcoal-ultra-light;
  }
}

.btn-primary,
.btn-secondary,
.btn-regressive,
.btn-danger {
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 -2px inset,
    rgba(50, 50, 93, 0.1) 0 2px 4px 0,
    rgba(0, 0, 0, 0.07) 0 1px 1px 0;
}

.btn-secondary {
  @include button-variant($ods-white, $ods-charcoal-light, $ods-white, $ods-charcoal-light);

  color: $ods-blue-core;

  &.hover,
  &:hover {
    color: $ods-blue-dim;
    background-color: #f0f2ff;
  }

  &:focus {
    border-color: $ods-blue-light;
  }

  &.disabled,
  &:disabled {
    color: $ods-charcoal-light;
    background-color: #fff;
  }
}
