.mandatory-field-symbol {
  color: #DC3030;
}


.bs-datepicker-head {
  background-color: #336eb4 !important;

  .current {
    border: 1px solid #558dcf;

    &:hover {
      background-color: #558dcf;
    }
  }

  .previous {
    &:disabled {
      // NOTE: The visibility is programmatically set on the style attribute, so we must use important
      // to override and hide the button
      visibility: hidden !important;
    }
  }

  .next {
    &:disabled {
      // NOTE: The visibility is programmatically set on the style attribute, so we must use important
      // to override and hide the button
      visibility: hidden !important;
    }
  }
}

.bs-datepicker-body {
  .weeks {
    // Weekday headings
    th {
      color: #62798a;
    }
  }


  .days {
    span {
      color: #2e384d !important;

      // NOTE: The .is-highlighted class is applied programmatically on hover
      &.is-highlighted {
        background-color: #eeeff0 !important;
      }

      &.is-other-month {
        color: #9cafbe !important;

        &.disabled {
          color: transparent;
        }
      }

      &.today {
        background-color: #f8f1dd;
      }

      &.selected {
        background-color: #336eb4 !important;
        color: #fff !important;

        &:hover {
          background-color: #336eb4 !important;
        }
      }

      &.disabled {
        color: #9cafbe !important;
      }

      // Date Rangge Selection
      &.in-range {
        &:not(.select-end) {
          &::before {
            background-color: #b9e2f8 !important;

            // NOTE: Because of our adjustments to font size and line spacing, the
            // day selector cells do not touch like they do on the original ngx-bootstrap
            // control, so we must expand the edges so they touch and don't show gaps
            left: -8px;
            right: -8px;
          }
        }

        // The .in-range class is also applied to the .select-end last element, so we
        // must turn off the background range bar manually
        &.select-end,
        &.is-highlighted {
          &::before {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  table td.week span{
    color: var(--color-blue-core) !important;
    font-weight: bold;
  }
  table td.active-week span:hover{
    background-color: #b9e2f8 !important;
  }

  .months,
  .years {
    span {
      color: #2e384d !important;
    }

    .disabled {
      span {
        color: #9cafbe;
      }
    }
  }
}


.table-header-content{
  //font-family: 'Nunito';
  font-weight: 600;
  color: #62798a;
  font-size:12px;
  text-align: left;
}

.datepicker-paddingleft {
  padding-left: 0.8rem;
}

.card .card-header .card-title {
  display: inline-block;
  margin-bottom: 0
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  color: #2e384d;
}
