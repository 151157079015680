// stylelint-disable selector-max-compound-selectors, max-nesting-depth, selector-max-specificity
// stylelint-disable no-descending-specificity

$table-cell-padding-x: 1.25rem;
$table-cell-padding-y: 0.875rem;
$table-cell-vertical-align: top;

.table {
  .cell-amount {
    text-align: right;
  }

  .cell-menu,
  .cell-btn {
    // Ensure buttons of different widths always appear in the center of the cell
    text-align: center;
    vertical-align: middle;

    // CSS trick to set the width of that cell equal to its contents
    width: 1px;

    .btn {
      padding: .125rem .5rem;
      color: $ods-blue-core;

      &:hover {
        background-color: $ods-charcoal-lighter;
      }
    }
  }

  thead {
    font-size: $font-size-semi-small;
  }

  thead,
  tfoot {
    tr {
      td,
      th {
        padding: $table-cell-padding-y $table-cell-padding-x;
        vertical-align: $table-cell-vertical-align;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: $table-cell-padding-y $table-cell-padding-x;
        vertical-align: $table-cell-vertical-align;

        .text-main {
          display: block;
        }

        // Remove the left border of the left-most cell in each row
        &:first-child {
          border-left: 0;
        }

        // Remove the right border of the right-most cell in each row
        &:last-child {
          border-right: 0;
        }

        .show {
          .btn {
            @include btn-hover-colors($ods-white, $ods-blue-core);
          }
        }

        .dropdown {
          &.show {
            .btn {
              &:hover {
                @include btn-hover-colors($ods-white, $ods-blue-core);
              }
            }
          }
        }
      }

      // Remove the bottom border of the bottom-most row
      // NOTE: The table body allows for multiple rows with heading cells; we don't
      // want to remove borders for the in-between rows or accidentally remove
      // the wrong border of a <th> that appears in the head, not the body
      &:last-child {
        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}
