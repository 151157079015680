$zindex-spinner-overlay-backdrop: $zindex-container-navbar-left + 1;
$zindex-spinner-overlay: $zindex-spinner-overlay-backdrop + 1;

.spinner-overlay {
  text-align: center;
  z-index: $zindex-spinner-overlay;

  // NOTE: The better approach for vertically centering content is to use flexbox;
  // however, because of our IE11 compatibility requirement, we use the traditional styling
  // recommended by the W3C
  // https://www.w3.org/Style/Examples/007/center.en.html
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  // Use fixed (instead of absolute) so that the spinner remains in the center of the screen
  // regardless of scroll position or viewport size
  position: fixed;

  .spinner-border {
    width: $spinner-size;
    height: $spinner-size;
    border-width: 0.5rem;
  }

  .spinner-body {
    margin-top: 1.5rem;
  }
}

.spinner-overlay-backdrop {
  @include backdrop-variant(
    $spinner-overlay-backdrop-color,
    $spinner-overlay-backdrop-opacity,
    $zindex-spinner-overlay-backdrop
  );
}
