// stylelint-disable max-nesting-depth

// Checkboxes and radios
//
.custom-control {
  margin-bottom: 0.5rem;
}

.custom-control-input {
  @include form-validation-state-selector(valid) {
    ~ .custom-control-label {
      color: $ods-charcoal-core;

      &::before {
        border-color: $ods-charcoal-light;
      }
    }

    &:checked {
      ~ .custom-control-label::before {
        border-color: $ods-blue-core;
        background-color: $ods-blue-core;
      }
    }
  }
}

// Custom control indicators
//
.custom-control-label {
  font-size: $font-size-base;
  font-weight: 400;
  color: $ods-charcoal-core;
}

// switches
//
.custom-switch {
  .custom-control-label {
    &::before {
      background-color: $ods-charcoal-light;
      border: none;
    }

    &::after {
      background-color: $ods-white;
    }
  }

  .custom-control-input {
    &[disabled] {
      ~ .custom-control-label {
        &::before {
          background-color: $ods-charcoal-lighter;
        }

        &::after {
          background-color: $ods-charcoal-ultra-light;
        }
      }
    }
  }
}

// select dropdowns
//
.custom-select {
  &:focus {
    box-shadow: none;
  }

  @include form-validation-state-selector(valid) {
    border-color: $ods-charcoal-light;

    &:focus {
      border-color: $ods-blue-core;
      box-shadow: none;
    }
  }
}
