// stylelint-disable font-family-no-missing-generic-family-keyword
@mixin icon-content-variant($icon, $icon-weight: $fa-far-weight) {
  // Set the content to a Font Awesome icon
  // https://fontawesome.com/how-to-use/on-the-web/advanced/css-pseudo-elements
  font-family: $fa-font-family;
  content: $icon;
  font-weight: $icon-weight;

  // Prevent unwanted hover effects when embedded in links
  display: inline-block;
}

@mixin icon-prefix-variant($icon, $icon-weight: $fa-far-weight) {
  &::before {
    @include icon-content-variant($icon, $icon-weight);
  }
}

@mixin icon-suffix-variant($icon, $icon-weight: $fa-far-weight) {
  &::after {
    @include icon-content-variant($icon, $icon-weight);
  }
}
// stylelint-enable font-family-no-missing-generic-family-keyword

// mixin to set the background-color and color of the text of a button
@mixin btn-hover-colors($btn-color, $btn-hover-color) {
  color: $btn-color;
  background-color: $btn-hover-color;
}

@mixin backdrop-variant($background-color, $opacity, $zindex) {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex;
  width: 100vw;
  height: 100vh;
  background-color: $background-color;
  opacity: $opacity;

  &.show {
    display: block;
  }
}

// stylelint-disable block-closing-brace-newline-after
// stylelint-disable at-rule-empty-line-before
@mixin form-validation-state-selector($state) {
  @if ($state == "valid" or $state == "invalid") {
    .was-validated &:#{$state},
    &.is-#{$state} {
      @content;
    }
  } @else {
    &.is-#{$state} {
      @content;
    }
  }
}
// stylelint-enable block-closing-brace-newline-after
// stylelint-enable at-rule-empty-line-before

@mixin alert-inline-variant($background, $border, $link-color, $icon-color: $link-color) {
  background-color: $background;
  border-color: $border;

  i {
    color: $icon-color;
  }

  .alert-link {
    color: $link-color;
  }
}

@mixin alert-toast-variant($text-color, $background-color, $border-color, $close-color) {
  color: $text-color;
  background-color: $background-color;
  border: 1px solid $border-color;

  i {
    color: $text-color;
  }

  .close {
    color: $close-color;
  }

  .alert-link {
    color: $text-color;
    font-weight: $font-weight-bold;
  }
}

// stylelint-disable selector-max-compound-selectors, max-nesting-depth, selector-max-specificity
// stylelint-disable no-descending-specificity
@mixin connected-table-variant() {
  .table {
    // Remove all borders and margin for an embedded table
    // so it appears flush with the edges of the card
    margin-bottom: 0;
    border: 0;

    // Mold the bottom of the table to the shape of the card
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    thead {
      tr {
        th {
          // Remove the left border of the left-most cell in each header row
          &:first-child {
            border-left: 0;
          }

          // Remove the right border of the right-most cell in each header row
          &:last-child {
            border-right: 0;
          }
        }

        // Remove the top border of the top-most header row
        // NOTE: The table head allows for multiple header rows; we don't
        // want to remove borders for the in-between rows, even in the head
        &:first-child {
          th {
            border-top: 0;
          }
        }

        // Remove the bottom border of the bottom-most header row
        // NOTE: The table head allows for multiple header rows; we don't
        // want to remove borders for the in-between rows, even in the head
        &:last-child {
          th {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
// stylelint-enable selector-max-compound-selectors, max-nesting-depth, selector-max-specificity
// stylelint-enable no-descending-specificity

@mixin blended-badge-variant($font-color, $background-color, $border-color) {
  color: $font-color;
  background-color: $background-color;
  border: 1px solid $border-color;
}

@mixin link-decorative-variant() {
  white-space: nowrap;

  // Decorative icons must display to the right of link text
  i,
  &::after {
    margin-left: $link-decoration-margin-left;
  }
}
