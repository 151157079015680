$btn-outline-group-toggle-border-radius: 1.125rem;

.btn-group {
  &.btn-outline-group-toggle {
    border: 2px solid $ods-charcoal-lighter;
    background-color: $ods-charcoal-lightest;
    margin-bottom: 1rem;

    @include border-radius($btn-outline-group-toggle-border-radius);

    .btn {
      font-weight: $font-weight-semi-bold;
      color: $ods-blue-core;
      padding: 0.375rem 1.25rem;
      cursor: pointer;
      transition: none;
      border: 2px solid transparent;

      &:hover {
        color: $ods-blue-dark;
      }

      &.active {
        font-weight: $font-weight-bold;
        color: $ods-charcoal-dark;
        border-color: $ods-blue-core;
        background-color: $ods-white;
        cursor: default;

        @include border-radius($btn-outline-group-toggle-border-radius);
      }
    }
  }
}
