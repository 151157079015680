.form-control {
  background-color: $ods-white;
  padding: 0.25rem 0.5rem;

  &.disabled,
  &:disabled {
    color: $form-control-disabled-color;
  }
}

.form-group {
  label {
    font-weight: $font-weight-semi-bold;
    opacity: $font-label-opacity;
  }

  .invalid-feedback {
    @include icon-prefix-variant($fa-exclamation-triangle);

    &::before {
      margin-right: $icon-decoration-margin-x;
    }
  }

  &.form-group-invalid {
    label {
      color: $form-control-invalid-color;
    }

    // NOTE: The default Bootstrap styling uses an adjacent detector (~)
    // to show/hide the error text, but because of the additional Angular tags
    // for custom controls (e.g., the date picker), this logic breaks. So
    // we apply more generic detection of invalid state.
    .invalid-feedback {
      display: block;
    }
  }
}

// Labels for horizontal forms
// https://getbootstrap.com/docs/4.3/components/forms/#horizontal-form
.col-form-label {
  font-size: $small-font-size;
  font-weight: $font-weight-medium;
}

// Multiple related inputs (segments) that are part of a single form group
// e.g., 3 inline inputs for social security number
.row {
  &.form-control-segments {
    // NOTE: Do NOT use the begins-with ^= selector because
    // <div class="col-2 foo"> is a match
    // <div class="foo col-2"> ISN'T a match
    [class*="col"] {
      &:not(:first-child) {
        padding-left: $form-control-segment-padding-x;
      }

      &:not(:last-child) {
        padding-right: $form-control-segment-padding-x;
      }
    }
  }
}

.is-required::after {
  content: "*";
  margin-left: 3px;
  color: $ods-red-core;
  font-weight: bold;
}
